<template>
  <LightboxBase
    data-testid="lightbox"
    style="z-index: 500"
    :streamfieldShowing = "streamfieldShowing"
    :title="title"
    :accessibilityTextFlag="accessibilityTextFlag"
    :accessibilityVideoFlag="accessibilityVideoFlag"
    :accessibilityMode="accessibilityMode"
    :hasAccessibleText="this.accessibleText"
    :hasAccessibleVideo="this.accessibleVideo"
    :isBeforeAfter="isBeforeAfter"
    @closed="$emit('closed')"
    @download="download()"
    @emit-back="emitBack()"
    @accessibility="accessibilityTextTrigger()"
    @accessibilityvideo="accessibilityVideoTrigger()"
    @accessibilitybeforeafter="accessibilityBeforeAfterTrigger()"
    :showDownload="canDownload"
    :showBack="hasBack"
    :showAccessibleVideo="isVideo"
    :dataFromQLB="dataFromQuestionLB"
    ref="lightbox_btn"
    :showLightbox="showLightbox"
    @hide-lightbox-buttons="handleHideLightboxButtons"
    @show-lightbox-buttons="handleShowLightboxButtons"
  >
    <LoadingOverlay v-if="loading" class="loading-overlay" title="" />
    <v-carousel
      height="90vh"
      :show-arrows="content.length > 1"
      :hide-delimiters="!showDelimiters"
      :hide-delimiter-background="true"
      dark
      v-model="index"
      touchless
    >
      <template v-slot:prev="{ on, attrs }">
        <v-btn
        data-testid="lightbox-previous-btn"
         v-show="showLightboxButtons"
          color="secondary"
          v-bind="attrs"
          v-on="on"
          rounded
          :fab="$vuetify.breakpoint.smAndDown"
          :small="$vuetify.breakpoint.smAndDown"
          aria-label="prev-visual"
          ><v-icon>mdi-chevron-left</v-icon
          ><span class="d-none d-md-inline">Prev</span></v-btn
        >
      </template>
      <template v-slot:next="{ on, attrs }">
        <v-btn
        data-testid="lightbox-next-btn"
         v-show="showLightboxButtons"
          color="secondary"
          v-bind="attrs"
          v-on="on"
          rounded
          :fab="$vuetify.breakpoint.smAndDown"
          :small="$vuetify.breakpoint.smAndDown"
          aria-label="next-visual"
          ><span class="d-none d-md-inline">Next</span
          ><v-icon>mdi-chevron-right</v-icon></v-btn
        >
      </template>

      <v-carousel-item v-for="(item, index) in content" :key="item.id" >
        <div class="lightbox-content" :data-testid="'lightbox-carousel-item' + index">
          <v-container
            fill-height
            fluid
            :class="multi ? 'lightbox-container-multi' : 'lightbox-container'"
          >
            <v-row align="center" justify="center" class="lightbox-row">
              <v-col class="fill-height lightbox-col">
                <LightboxIframeCard
                  data-testid="lightbox-iframe"
                  v-if="item.type === 'iframe'"
                  :data="item.value"
                  :accessibilityTextFlag="accessibilityTextFlag"
                  :ariaDescription="'accessibilityDescriptionIframe' + index"
                />
                <LightboxImageCard
                  data-testid="lightbox-image"
                  v-if="item.type === 'image'"
                  :data="item.value"
                  :accessibilityTextFlag="accessibilityTextFlag"
                  :ariaDescription="'accessibilityDescriptionImage' + index"
                />
                <LightboxVideoCard
                  data-testid="lightbox-video"
                  v-if="item.type === 'video'"
                  @itemTypeChanged="emitItemType('video')"
                  :data="item.value"
                  :accessibilityTextFlag="accessibilityTextFlag"
                  :accessibilityVideoFlag="accessibilityVideoFlag"
                  :currentContent="currentContentFile"
                  :ariaDescription="'accessibilityDescriptionVideo' + index++"
                />
                <LightboxBranchingVideo360Card
                  v-if="item.type === 'branching_video_360'"
                  :data="item.value"
                />
                <LightboxBranchingVideoCard
                  v-if="item.type === 'branching_video'"
                  :data="item.value"
                  data-testid="lightbox-branching-video"
                />
                <LightboxVideo360Card
                  v-if="item.type === 'video_360'"
                  data-testid="lightbox-video360"
                  :data="item.value"
                  :accessibilityTextFlag="accessibilityTextFlag"
                  :accessibilityVideoFlag="accessibilityVideoFlag"
                  :accessibilityMode="accessibilityMode"
                  :currentContent="currentContentFile"
                  :ariaDescription="'accessibilityDescriptionVideo' + index++"
                />
                <LightboxDocumentCard
                  data-testid="lightbox-document"
                  v-if="item.type === 'document'"
                  :data="item.value"
                  :accessibilityTextFlag="accessibilityTextFlag"
                  :ariaDescription="'accessibilityDescriptionDocument' + index"
                />
                <LightboxCustomPanoramaCard
                  v-if="item.type === 'panoramaroompopup'"
                  :data="item.value"
                  :accessibilityTextFlag="accessibilityTextFlag"
                  :ariaDescription="'accessibilityDescriptionVideo' + index"
                />
                <LightboxWagtailPage
                  data-testid="lightbox-page"
                  v-if="item.type === 'page'"
                  :data="item.value"
                  :accessibilityTextFlag="accessibilityTextFlag"
                  :ariaDescription="
                    'accessibilityDescriptionWagtailPage' + index
                  "
                />
                <QuestionsLightbox
                  v-if="item.type === 'questionBlock'"
                  ref="childComponentRef"
                  @answer-val-changed="handleDataFromChild"
                  :data="item.value"
                  :accessibility="accessibilityTextFlag"
                  :ariaDescription="
                    'accessibiilityDescriptionQuestionBlock' + index
                  "
                >
                </QuestionsLightbox>
                <LightboxWagtailPageStruct
                  v-if="item.type === 'page_struct'"
                  data-testid="lightbox-page-struct"
                  :data="item.value"
                  :accessibilityTextFlag="accessibilityTextFlag"
                  :ariaDescription="
                    'accessibilityDescriptionPageStructure' + index
                  "
                />
                <LightboxBeforeAfterCard
                  data-testid="lightbox-beforeafter"
                  v-if="item.type === 'beforeafter'"
                  :data="item.value"
                  :accessibilityTextFlag="accessibilityTextFlag"
                  :accessibleSliderValue="sliderValue"
                  :ariaDescription="
                    'accessibilityDescriptionBeforeAfterImage' + index
                  "
                />
                <LightboxBiographyCard
                  v-if="item.type === 'biography'"
                  :data="item.value"
                  :accessibilityTextFlag="accessibilityTextFlag"
                  :ariaDescription="'accessibilityDescriptionBiography' + index"
                />
                <LightboxAudio
                  data-testid="lightbox-audio"
                  v-if="item.type === 'audio'"
                  :data="item.value"
                />
                <LightboxExternalLinkCard
                  v-if="item.type === 'external_link'"
                  :data="item.value"
                  :accessibilityTextFlag="accessibilityTextFlag"
                  :ariaDescription="'accessibilityDescriptionExternalLink' + index"
                  />
                <LightboxInternalLinkCard
                  v-if="item.type === 'internal_page_link'"
                  :data="item.value"
                  :accessibilityTextFlag="accessibilityTextFlag"
                  :ariaDescription="'accessibilityDescriptionInternalLink' + index"
                  />

              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-carousel-item>
    </v-carousel>
  </LightboxBase>
</template>

<script>
// import ImageLightbox from "./ImageLightbox.vue"
import LightboxBase from "./LightboxBase.vue";
import LightboxImageCard from "./LightboxImageCard.vue";
import LightboxVideoCard from "./LightboxVideoCard.vue";
import LightboxDocumentCard from "./LightboxDocumentCard.vue";
import LightboxWagtailPage from "./LightboxWagtailPage.vue";
import LightboxWagtailPageStruct from "./LightboxWagtailPageStruct.vue";
import LightboxIframeCard from "./LightboxIframeCard.vue";
import LightboxBeforeAfterCard from "./LightboxBeforeAfterCard.vue";
import LoadingOverlay from "./LoadingOverlay.vue";
import LightboxPanoramaCard from "./LightboxPanoramaCard.vue";
import LightboxVideo360Card from "./LightboxVideo360Card.vue";
import LightboxBiographyCard from "./LightboxBiographyCard.vue";
import LightboxCustomPanoramaCard from "./LightboxCustomPanoramaCard.vue";
import QuestionsLightbox from "./QuestionsLightbox.vue";
import Axios from "axios";
import LightboxBranchingVideo360Card from "./LightboxBranchingVideo360Card.vue";
import LightboxBranchingVideoCard from "./LightboxBranchingVideoCard.vue";
import LightboxAudio from "./LightboxAudio.vue";
import LightboxExternalLinkCard from "./LightboxExternalLinkCard.vue";
import LightboxInternalLinkCard from "./LightboxInternalLinkCard.vue"

export default {
  props: ["content", "startIndex", "showLightbox", "lightboxTrigger", "streamfieldShowing"],
  data: () => ({
    showLightboxButtons: true,
    showDelimiters: false,
    dialog: true,
    index: 0,
    accessibilityTextFlag: false,
    accessibilityVideoFlag: false,
    accessibleText: false,
    accessibleBeforeAfter: false,
    accessibleVideo: false,
    sliderValue: 0.5,
    dataFromQuestionLB: false,
    isMobile: false,
    currentContentFile: null,
    goToOptions: {
      duration: 0,
      offset: 0,
      easing: "linear",
    },
    loading: true
  }),
  // components: { LightboxBase },
  components: {
    LightboxAudio,
    LightboxBase,
    LightboxImageCard,
    LightboxVideoCard,
    LightboxDocumentCard,
    LightboxWagtailPage,
    LightboxWagtailPageStruct,
    LightboxIframeCard,
    LightboxBeforeAfterCard,
    LoadingOverlay,
    LightboxPanoramaCard,
    LightboxVideo360Card,
    LightboxBiographyCard,
    LightboxBranchingVideo360Card,
    LightboxCustomPanoramaCard,
    QuestionsLightbox,
    LightboxBranchingVideoCard,
    LightboxExternalLinkCard,
    LightboxInternalLinkCard
  },

  computed: {
    currentItem() {
      if (this.content[this.index] !== undefined)
        return this.content[this.index];
      return undefined;
    },
    canDownload() {
      if (this.currentItem === undefined ||  this.currentItem.value.downloadable === false) return false;

      
      let downloadTypes = ["image", "document"];
      return downloadTypes.includes(this.currentItem.type);
    },
    hasBack() {
      if (this.currentItem === undefined) return false;

      let questionBlock = ["questionBlock"];
      return questionBlock.includes(this.currentItem.type);
    },
    isVideo() {
      if (this.currentItem === undefined) return false;

      let videoType = ["video", "video_360"]
      console.log(this.currentItem.type ,"class zero", videoType.includes(this.currentItem.type))

      return videoType.includes(this.currentItem.type);
    },
    isBeforeAfter() {
      if (this.currentItem === undefined) return false;

      let beforeafter = ["beforeafter"];
      return beforeafter.includes(this.currentItem.type);
    },
    title() {
      if (this.content[this.index] !== undefined)
        return this.content[this.index].value.title;
      return "";
    },
    showButtons() {
      if (this.$vuetify.breakpoint.name === "xs") return false;

      if (this.content !== undefined) if (this.content.length > 1) return true;
      return false;
    },

    multi() {
      if (this.content !== undefined && this.content.length > 1) return true;
      return false;
    },
    accessibilityMode() {
      return this.$store.state.wagtail.accessibilityMode;
    },
    currOpacity() {
      if (this.isMobile) return 0.5;
      return 1;
    },
  },
  // beforeCreate(){
  //         let html =  document.querySelector('html');

  //     html.style.overflow = 'hidden'
  //         this.$refs.lightbox_btn.$el.focus();

  // },
  mounted() {
    console.log(this.$refs.lightbox_btn.clientWidth, "test-vid");
    console.log(this.content, "content time");
    console.log(this.startIndex, "index of current gal");
    this.$vuetify.goTo(0, this.goToOptions);
    let html = document.querySelector("html");

    html.style.overflow = "hidden";

    // this.index = this.startIndex || 0;
 
    this.updateIndex();
    this.$refs.lightbox_btn.$el.focus();
    window.addEventListener("resize", this.onResize, { passive: true });
    if (window.screen.width < 600) {
      this.isMobile = true;
    }


    // On mount, show/hide delimeters
    if (this.content !== undefined && this.content.length > 1) {
      this.showDelimiters = true;
    } else {
      this.showDelimiters = false;
    }
    this.loading = false
  },
  beforeDestroy() {
    console.log("destroy");
    let html = document.querySelector("html");

    html.style.overflow = "scroll";
    html.style.overflowX = "hidden";
  },
  watch: {
    showLightbox(value) {
      if (value) {
        for (const item of this.content) {
          if (item.type === 'external_link') {
            window.open(item.value.url, '_blank')
            break;
          } else if (item.type === 'internal_page_link') {
            const url = new URL(item.value.page.meta.html_url)
            window.open(url.pathname, '_blank')
            break;
          }
        }
      }
    },
    content() {
      console.log("content update lightbox gall");
      this.updateIndex();
      for (var i = 0; i < this.content.length; i++) {
        if (this.content[i] <= this.content.length) {
          this.content[0].value.push({ testtest: i });
        }
      }
      // On content change, show/hide delimeters
      if (this.content !== undefined && this.content.length > 1) {
        this.showDelimiters = true;
      } else {
        this.showDelimiters = false;
      }

      if (this.content.length > 0) {
        this.emitItemType(this.content[this.index].type);
      }

    },
    startIndex(result) {
      console.log(result, "update index");
      this.updateIndex();
    },
    currentItem(val) {
      this.emitItemType(val.type)
      console.log(val, "current item val")

      if(val.type === 'video'){
        if(this.accessibilityVideoFlag === false ){
          this.currentContentFile = val.value.file
          console.log("cca watcher 1")
        }else if (this.accessibilityVideoFlag && val.value.accessible_video != null){
            this.currentContentFile = val.value.accessible_video  
            console.log("cca watcher 2")
        }else if(this.accessibilityVideoFlag && val.value.accessible_video == null){
           this.currentContentFile = val.value.file 
           console.log("cca watcher 3")
        } 
      }else if(val.type === 'video_360'){
        if(this.accessibilityVideoFlag === false ){
          this.currentContentFile = val.value.video.file
          console.log("cca watcher 1 360")
        }else if (this.accessibilityVideoFlag && val.value.video.accessible_video != null){
            this.currentContentFile = val.value.video.accessible_video  
            console.log("cca watcher 2 360")
        }else if(this.accessibilityVideoFlag && val.value.accessible_video == null){
              this.currentContentFile = val.value.video.file
        }
      }

      // this.emitItemType(val.type)
      if (!this.currentItem) return;
      if(this.currentItem.type === 'video'){
        if (
          this.currentItem.value &&
          this.currentItem.value.videoAccessibility &&
          this.currentItem.value.videoAccessibility.video &&
          this.currentItem.value.videoAccessibility.video.file
        ) {
          this.accessibleText = true;
          this.accessibleVideo = true;
        } else if (
          (this.currentItem.value.accessibility_text === undefined &&
            this.currentItem.value.accessible_video === undefined) ||
          (this.currentItem.value.accessibility_text == `` &&
            this.currentItem.value.accessible_video == null) ||
          (this.currentItem.value.accessibility_text == "" &&
            this.currentItem.value.accessible_video === "")
        ) {
          console.log("gomen2")
          this.accessibleText = false;
          this.accessibleVideo = false;
        } else if (
          (this.currentItem.value.accessibility_text != undefined &&
            this.currentItem.value.accessible_video != undefined) ||
          (this.currentItem.value.accessibility_text != null &&
            this.currentItem.value.accessible_video != null) ||
          (this.currentItem.value.accessibility_text != `` &&
            this.currentItem.value.accessible_video != "") ||
          (this.currentItem.value.video.accessibility_text != undefined &&
            this.currentItem.value.video.accessible_video != undefined)
        ) {
                  console.log("gomen3")

          this.accessibleVideo = true;
          this.accessibleText = true;
        } else if (
          (this.currentItem.value.accessibility_text === undefined &&
            this.currentItem.value.accessible_video != undefined) ||
          (this.currentItem.value.accessibility_text === null &&
            this.currentItem.value.accessible_video != null) ||
          (this.currentItem.value.accessibility_text == `` &&
            this.currentItem.value.accessible_video != "")  ||
          (this.currentItem.value.video.accessibility_text === undefined &&
            this.currentItem.value.video.accessible_video != undefined) 
        ) {
                  console.log("gomen4")

          this.accessibleText = false;
          this.accessibleVideo = true;
        } else if (
          (this.currentItem.value.accessibility_text != undefined &&
            this.currentItem.value.accessible_video === undefined) ||
          (this.currentItem.value.accessibility_text != null &&
            this.currentItem.value.accessible_video === null) ||
          (this.currentItem.value.accessibility_text != `` &&
            this.currentItem.value.accessible_video === "") ||
          (this.currentItem.value.video.accessibility_text != undefined &&
            this.currentItem.value.video.accessible_video === undefined) 
        ) {
                  console.log("gomen5")

          this.accessibleText = true;
          this.accessibleVideo = false;
        }
      }else if(this.currentItem.type == 'video_360'){
        if(this.currentItem.value.video.accessibility_text != undefined 
        && this.currentItem.value.video.accessible_video != undefined
        ){
          this.accessibleText = true;
          this.accessibleVideo = true;
        }else if (
          this.currentItem.value.video.accessibilty_text != undefined &&
          this.currentItem.value.video.accessible_video === undefined
        ){
           this.accessibleText = true;
          this.accessibleVideo = false;
        }else if (
          this.currentItem.value.video.accessibilty_text === undefined &&
          this.currentItem.value.video.accessible_video != undefined
          ){
            this.accessibleText = false;
          this.accessibleVideo = true;
          }else if (
          this.currentItem.value.video.accessibilty_text === undefined &&
          this.currentItem.value.video.accessible_video === undefined
          ){
            this.accessibleText = false;
          this.accessibleVideo = false;
          }
      }
    },
    accessibilityVideoFlag(){
      if(this.currentItem.type === 'video'){
        if(this.accessibilityVideoFlag === false ){
          this.currentContentFile = this.currentItem.value.file
          console.log("cca watcher 1")
        }else if (this.accessibilityVideoFlag && this.currentItem.value.accessible_video != null){
            this.currentContentFile = this.currentItem.value.accessible_video  
            console.log("cca watcher 2")
        }else if(this.accessibilityVideoFlag && this.currentItem.value.accessible_video == null){
           this.currentContentFile = this.currentItem.value.file 
           console.log("cca watcher 3")
        } 
      }else if(this.currentItem.type === 'video_360'){
        if(this.accessibilityVideoFlag === false ){
          this.currentContentFile = this.currentItem.value.video.file
          console.log("cca watcher 1")
        }else if (this.accessibilityVideoFlag && this.currentItem.value.video.accessible_video != null){
            this.currentContentFile = this.currentItem.value.video.accessible_video  
            console.log("cca watcher 2")
        }else if(this.accessibilityVideoFlag && val.value.accessible_video == null){
              this.currentContentFile = this.currentItem.value.video.file
        }
      }
    },
    isVideo() {
      if (this.currentItem.value.accessible_video === undefined || this.currentItem.value.video.accessible_video === undefined){
        console.log("true it is a video")
        return false
      }else {
        console.log("false it is not a video")
        return true
      } 
    },

    dialog(newVal) {
      console.log("new value for vid", newVal);
    },
  },
  methods: {
    handleHideLightboxButtons() {
      this.showLightboxButtons = false;
      this.showDelimiters = false;
    },
    emitItemType(type) {
      this.$emit('item-type-changed', type);
    },
    handleShowLightboxButtons() {
      this.showLightboxButtons = true;

      if (this.content !== undefined && this.content.length > 1) {
        this.showDelimiters = true;
      }
    },
    updateIndex() {
      if (this.startIndex !== undefined) this.index = this.startIndex;
      else this.index = 0;
    },
    next() {
      this.index++;
      if (this.index >= this.content.length) this.index = 0;
    },
    prev() {
      this.index--;
      if (this.index < 0) this.index = this.content.length - 1;
    },
    onResize() {
      if (window.screen.width < 600) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    handleDataFromChild(data) {
      console.log(data, "@@@@LBG");
      if (data !== undefined) {
        this.dataFromQuestionLB = true;
      } else this.dataFromQuestionLB = false;
    },
    download() {
      if (this.currentItem === undefined) return;

      let url = new URL(this.currentItem.value.file);
      url = url.pathname;
      let filename = url.substring(url.lastIndexOf("/") + 1);

      if (this.currentItem.type === "image") {
        this.downloadItem(this.currentItem.value.file, filename);
      } else if (this.currentItem.type === "document") {
        this.downloadItem(this.currentItem.value.file, filename);
      }
    },
    emitBack() {
      this.$refs.childComponentRef[0].flushAnswer();
      console.log(childComponentInstance, "@@@@childco");
    },
    downloadItem(url, label) {
      Axios.get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: this.getMimetype(label),
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = label;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
    getMimetype(filename) {
      var extToMimes = {
        jpg: "image/jpeg",
        png: "image/png",
        pdf: "application/pdf",
      };
      var fileExt = filename.split(".").pop();
      return extToMimes[fileExt];
    },
    accessibilityTextTrigger() {
      if (this.accessibilityTextFlag == false) {
        this.accessibilityTextFlag = true;
      } else {
        this.accessibilityTextFlag = false;
      }
    },
    accessibilityVideoTrigger() {
      if (this.accessibilityVideoFlag == false) {
        this.accessibilityVideoFlag = true;
      } else {
        this.accessibilityVideoFlag = false;
      }
    },
    accessibilityBeforeAfterTrigger() {
      if (this.accessibleBeforeAfter == true) {
        this.accessibleBeforeAfter = false;
        this.sliderValue = 1.0;
      } else {
        this.accessibleBeforeAfter = true;
        this.sliderValue = 0.01;
      }
    },
    accessibilityModeFlag() {
      console.log("activated");
      if (this.accessibilityMode == true) {
        this.accessibilityFlag = false;
        this.$store.commit(
          "wagtail/setAccessibiltyMode",
          this.accessibilityFlag,
          { root: true }
        );
        console.log(this.accessibilityFlag, "access flag");
      } else {
        this.accessibilityFlag = true;
        this.$store.commit(
          "wagtail/setAccessibiltyMode",
          this.accessibilityFlag,
          { root: true }
        );
        console.log(this.accessibilityFlag, "access flag");
      }
    },
  },
};
</script>

<style scoped>
.lb-debug {
  background-color: blue !important;
}

.lb-content {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
}

.lightbox-content {
  width: 100%;
  left: 40px;
  right: 40px;
  height: 100%;
  background: none;
}

.lightbox-container-multi {
  background: none;
  padding-left: 132px;
  padding-right: 132px;
  padding-top: 0px;
  padding-bottom: 48px;
}

.lightbox-container {
  background: none;
  padding-left: 48px;
  padding-right: 48px;
  padding-top: 0px;
  padding-bottom: 48px;
  overflow: hidden !important;
}

.lightbox-row {
  background: none;
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100%;
}

.lightbox-col {
  padding: 0px;
  margin: 0px;
}

.loading-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

@media (max-width: 960px) {
  .lightbox-container-multi {
    background: none;
    padding-left: 64px;
    padding-right: 64px;
    padding-top: 0px;
    padding-bottom: 48px;
  }

  .lightbox-container {
    background: none;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 0px;
    padding-bottom: 48px;
  }

  ::v-deep .v-window__next {
    border-radius: 28px;
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    margin: 0 16px;
    z-index: 1;
  }
  ::v-deep .v-window__prev {
    border-radius: 28px;
  }
}
</style>
